@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

body {
  background-color: #cfcfcf;
}

* {
  margin: 0;
  box-sizing: border-box;
}

:root {
  --light-gray: rgb(201, 201, 201);
  --icon-size: 23px;
  --font-size: 18px;
  --icon-size-mobile: 25px;
  --icon-border-radius: 50%;
  --icon-color: black;
  --icon-color-hover: white;
  --icon-background: #ffffff;
  --icon-background-hover: #797979;
  --icon-stroke: rgb(0, 0, 0);
  --icon-stroke-hover: rgb(0, 0, 0);
  --icon-stroke-width: 2px;
  --borderWidth: 10px;
  --height: 60px;
  --width: 30px;
  --borderColor: #78b13f;
}

.kaptcha-title {
  font-size: 3rem;
  font-family: 'Montserrat', sans-serif;
  padding: 30px 10px 0px 30px;
}

.kaptcha-author {
  font-size: 1.3rem;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 0px 0px 40px;
}

.kaptcha-desc {
  font-family: "Lato",sans-serif;
  font-size: 1rem;
  padding: 30px 30px 0px 30px;
}

.page-load {
  box-sizing: border-box;
  position: relative;
  margin: 200px auto;
  text-align: center;
}


.captcha-outer {
  box-sizing: border-box;
  position: relative;
  height: 130px;
  width: 130px;
/*   border: 2px solid blue; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: #ffffff;
  margin: 200px auto;
  border-radius: 5px;
  padding: 35px 0 0 35px;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.info {
  position: absolute;
  font-weight: bold;
  top: 5px;
  right: 5px;
  font-family: 'Lato', sans-serif;
}

.info:hover {
  cursor: pointer;
  color: gray;
}


.circle-loader {
  transition: box-shadow .3s;
  border: 3px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  z-index: 1;
  -webkit-transition: box-shadow 0.35s ease-out;
  -moz-transition: box-shadow 0.35s ease-out;
  -o-transition: box-shadow 0.35s ease-out;
  transition: box-shadow 0.35s ease-out; 
}

.circle-loader-animation {
  border-left-color: #5cb85c;
  -webkit-animation: loader-spin 1s infinite linear;
  -moz-animation: loader-spin 1s infinite linear;
  -o-animation: loader-spin 1s infinite linear;
  animation: loader-spin 1s infinite linear;
  position: relative;
}

.circle-loader:hover {
  cursor: pointer;
  box-shadow: inset 0px 0px 10px 2px rgba(195,195,195,0.6);
}

.load-complete:hover {
  cursor: pointer;
  box-shadow: inset 0px 0px 10px 2px rgba(195,195,195,0.6);
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  -webkit-transition: border 1800ms ease-out;
  -moz-transition: border 1800ms ease-out;
  -o-transition: border 1800ms ease-out;
  transition: border 1800ms ease-out;
  -webkit-transition: box-shadow 0.35s ease-out;
  -moz-transition: box-shadow 0.35s ease-out;
  -o-transition: box-shadow 0.35s ease-out;
  transition: box-shadow 0.35s ease-out; 
}

.checkmark {
  display: block;
}


.checkmark.draw:after {
  animation-duration: 1800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 50px;
  width: 25px;
  transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: "";
  left: 8px;
  top: 40px;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 25px;
    opacity: 1;
  }
  40% {
    height: 50px;
    width: 25px;
    opacity: 1;
  }
  100% {
    height: 50px;
    width: 25px;
    opacity: 1;
  }
}




.captcha-inner {
  box-sizing: border-box;
  position: absolute;
  height: 100px;
  width: 100px;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  border: 2px solid var(--light-gray);
}

.line {
  position: absolute;
  width: 100%;
  top: 30px;
  border-top: 2px solid var(--light-gray);
}

.captcha-inner:hover {
  cursor: pointer;
  background-color: gray;
}

.title {
  position: absolute;
  top: 5px;
  left: 5px;
  font-weight: bold;
}

.verify {
  position: absolute;
  font-size: 25px;
  font-weight: bold; 
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
}

.verify:hover {
  cursor: pointer;
  color: gray;
  text-decoration: underline;
}


.loading {
  height: 0;
  width: 0;
  margin: 25px auto;
  padding: 20px;
  border: 3px solid #ccc;
  border-radius: 50%;
  top: 50%;
  border-right-color: #888;
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.check {
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
}


.info-icon {
  position: absolute;
  right: 3px;
  top: 3px;
  color: var(--icon-color);
  background: var(--icon-background);
  font-size: var(--font-size);
  overflow:hidden;
  border-radius: var(--icon-border-radius);
  border: solid var(--icon-stroke-width) var(--icon-stroke);
  width: var(--icon-size);
  height: var(--icon-size);
  cursor: pointer;
  opacity: 0.9;
  transition: 0.2s;
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
}


.info-icon:hover {
  background: var(--icon-background-hover);
  border: solid var(--icon-stroke-width) var(--icon-stroke-hover);
  opacity: 0.98;
}


/* Media queries to size Icon for targeted devices */

@media (max-width: 971px) {
  .info-icon {
    font-size: var(--icon-size-mobile);
    width: var(--icon-size-mobile);
    height: var(--icon-size-mobile);
  }
}


.c-content {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  /* padding-top: 100px; */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  height: 100%;
  width: 100vw;
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

.fadein-animation {
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
}

.fadeOut-animation {
  animation-name: fadeOut;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
}

/* Modal Content */
.modal-content {
  background-color: #fafafa;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2.4rem;
  max-width: 700px;
  max-height: 600px;
  border-radius: 5px;
}

.c-content .load-padding {
  margin: 60px;
}

/* The Close Button */
.close {
  color: #000000;
  float: right;
  font-size: 30px;
}

.close:hover,
.close:focus {
  color: rgb(171, 171, 171);
  text-decoration: none;
  cursor: pointer;
}

.question-type-1 {
  padding: 10px;
}

.question-type-4 {
  padding: 10px;
}

.modal-content .instruction {
  font-family: 'Lato', sans-serif;
  display: block;
  padding: 10px 0px 25px 0px;
  color:black;
}

.modal-content .questionLabel {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  display: block;
  padding: 0 0 10px 0;
  font-weight: bold;
  color:#000000;
}


.modal-content .questionImage {
  display: block;
  padding: 10px;
  max-width: 100%;
  margin: auto;
  border: 5px solid black;
}

.modal-content .answerTag {
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  padding: 20px 0px 0px 0px;
  font-weight: bold;
  display: block;
  color:rgb(0, 0, 0);
}

.modal-content .input {
  font-size: 15px;
  font-family: 'Lato', sans-serif;
  padding: 10px;
  margin: 15px 0;
  width: 100%;
}

.modal-content .input:focus {
  outline: 2px solid rgb(0, 0, 0);
}

input,
.input,
textarea,
select {
  -webkit-appearance: none;
  background-color: white;
  color: inherit;
  outline: none;
  resize: none;
  box-sizing: border-box;
  border-radius: 0em;
  border: 1px solid lightgray;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 10px;
  margin-right: 0.6em;
  min-width: 15em;
}

.modal-content .buttonParent {
  text-align: right;
  width: 100%;
}

.modal-content .nextButton {
  padding: 10px;
  font-size: 15px;
  background-color: #000;
  color: white;
  border-radius: 3px;
}
.modal-content .nextButton:hover {
  cursor: pointer;
  color: black;
  background-color: #d0d0d0;
}

.frame {
  height: 150px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.image {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  bottom: -100%;
  margin: auto;
  height: 100%;
}

.image:hover {
  cursor: pointer;
  transform: scale(3);
}


.captcha-outer .error {
  position: absolute;
  bottom: -50px;
  color: red;
  width: 112px;
  font-size: 12px;
  left: 0;  
}

.captcha-outer .error:hover {
  cursor: pointer;
  text-decoration: underline;
}

.info-content .kaptcha-info {
  display:inline-block;
  position: relative;
  padding: 10px;
  color: black;
}


.c-content .info-content {
  width: 450px;
}